import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const element = document.getElementById('form-plugin') as HTMLElement;
const attributes = element.attributes;

const props: { [key:string]: string } = {};

for (let i = 0; i < attributes.length; i++) {
  const attrib = attributes[i];
  if (attrib.name.includes('prop-')) {
    props[attrib.name.split("prop-").pop() as string] = attrib.value;
  }
}

const root = ReactDOM.createRoot(element);
root.render(
  <React.StrictMode>
    <App {...props} />
  </React.StrictMode>,
);
