import { useEffect, useState } from 'react';

import Form from '../../components/Form/Form.component';
import { FormDefinition } from '../../components/Form/Form.types';
import { getFields } from './Form.service';

const FormPage = () => {
  const [definition, setDefinition] = useState<FormDefinition>({
    fields: [],
  });

  useEffect(() => {
    async function init() {
      const response = await getFields();
      setDefinition(response);
    }

    init();
  }, []);

  return (
    <div>
      <Form {...definition} />
    </div>
  );
};

export default FormPage;
