import React from 'react';

import FormPage from './pages/Form/Form.page';

import './App.css';

type AppProps = { [key: string]: string };

const App: React.FC<AppProps> = (props) => {
  return (
    <div className="App">
      {props.name && <h1 className="text-6xl">Hello {props.name}!</h1>}
      <FormPage />
    </div>
  );
};

export default App;
