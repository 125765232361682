import React from 'react';

import { FormDefinition } from './Form.types';

type FormProps = FormDefinition;

const Form: React.FC<FormProps> = ({ fields = [], name }) => {
  return (
    <form>
      <h2>{name}</h2>
      {fields.map((field) => (
        <div key={field.id}>
          <label>{field.label_real}</label>
        </div>
      ))}
    </form>
  );
};

export default Form;
